<template>
  <card-loader v-if="loading && useLoading" />
  <v-row class="card-container" no-gutters v-else>
    <v-col xl="8" lg="9" md="11" sm="11" cols="12">
      <v-card :class="{ 'card-wrapper mx-auto': $vuetify.breakpoint.smAndUp }" elevation="2" tile>
        <flex-view-layout class="flex-layout-container">
          <template v-slot:content>
            <slot name="header"></slot>
            <div class="card-content">
              <slot name="content"></slot>
            </div>
          </template>
          <template v-slot:footer>
            <slot name="footer"></slot>
          </template>
        </flex-view-layout>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import FlexViewLayout from '@/layouts/FlexViewLayout'
import CardLoader from '@/components/Loader/CardLoader'

export default {
  components: {
    FlexViewLayout,
    CardLoader
  },
  props: {
    useLoading: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('shared', ['loading'])
  }
}
</script>
<style scoped>
.card-container {
  justify-content: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.card-wrapper {
  min-height: 38.75rem;
}
.flex-layout-container {
  min-height: inherit;
  padding: 2.125rem 2.75rem;
}
.card-content {
  text-align: left;
  color: var(--app-text-color);
}

@media only screen and (max-width: 37.5rem) {
  .card-container {
    padding-top: 0;
    padding-bottom: 0;
  }
  .card-wrapper {
    min-height: inherit;
  }
  .flex-layout-container {
    padding: 1.5rem 1.25rem 0.25rem 1.25rem;
  }
}
</style>
