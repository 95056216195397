<template>
  <div class="flex-wrapper" :style="$vuetify.breakpoint.xsOnly ? mobileStyles : ''">
    <div class="flex-content">
      <slot name="content"></slot>
    </div>
    <div class="footer-wrapper">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      mobileStyles: {}
    }
  },
  methods: {
    calcViewport () {
      this.mobileStyles = {
        minHeight: [(window.innerHeight - 48) + 'px']
      }
    }

  },
  created () {
    window.addEventListener('resize', this.calcViewport)
  },
  destroyed () {
    window.removeEventListener('resize', this.calcViewport)
  },
  mounted () {
    this.calcViewport()
  }
}
</script>
<style scoped>
.flex-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.flex-content {
  flex-grow: 100;
}

@media only screen and (max-width: 37.5rem) {
  .footer-wrapper {
    padding-bottom: 1rem;
  }
}
</style>
