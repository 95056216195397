<template>
    <card-container>
    <template v-slot:content class="card-content" v-if="collectionDetail">
      <div class="collection-title">
        Hora de Recodiga {{ collectionDetail.start_time }} - {{ collectionDetail.final_time }}
      </div>
      <div class="collection-code">
        Codigo: {{collectionDetail.collection_code}}
      </div>
      <div class="creation-date">
        {{ dateFormat(collectionDetail.creation_date, 'D MMM YYYY') }}
      </div>
      <div class="section-container app-border-top">
        <div class="section-title">
          Ordenes
        </div>
        <div v-for="(order, index) in collectionDetail.orders" :key="order.order_id+'_'+index" @click="detailOrder(order.order_id)">
          <list-item-container :printBorder="index < count">
            <template v-slot:avatar>
              <v-icon class="avatar">mdi-shopping</v-icon>
            </template>
            <template v-slot:content>
              <div class="list-item-title first-capital-letter">
                Orden {{ order.order_number }}
              </div>
              <div class="order-creation-date">
                {{ dateFormat(order.creation_date, 'D MMM YYYY') }}
              </div>
              <div class="order-price">
                {{ getCollectionOrderPrice(index) }}
              </div>
              <div class="list-item-buyer">
                Comprado por {{ order.user.first_name }} {{ order.user.last_name }}
              </div>
            </template>
          </list-item-container>
        </div>
      </div>
    </template>
  </card-container>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import navigationMixin from '@/mixins/navigation'
import CardContainer from '@/components/Card/CardContainer'
import ListItemContainer from '@/components/List/ListItemContainer'
import { dateFormat } from '@/shared/format'

export default {
  mixins: [navigationMixin],
  components: {
    CardContainer,
    ListItemContainer
  },
  computed: {
    ...mapState('collection', ['collectionDetail']),
    ...mapGetters('collection', ['getCollectionOrderPrice']),
    count () {
      return this.collectionDetail ? this.collectionDetail.orders.length - 1 : 0
    }
  },
  methods: {
    ...mapActions('collection', ['getCollection']),
    dateFormat,
    detailOrder (id) {
      this.goToRouteByName('order-detail', { id })
    }
  },
  mounted () {
    this.getCollection(this.$route.params.id)
  }
}
</script>
<style scoped>
.card-content {
  height: 100%;
}
.collection-title {
  font-size: 1.125rem;
  font-weight: bold;
}
.collection-code {
  font-size: 0.875rem;
  font-weight: 700;
  padding-bottom: 0.75rem;
}
.creation-date {
  font-size: 0.875rem;
  padding-bottom: 0.75rem;
  color: #7D7D7DFF;
}
.section-container {
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}
.section-title {
  font-size: 1rem;
  font-weight: 600;
}
.section-description {
  margin-top: 0.25rem;
  font-size: 0.875rem;
}
.avatar {
  color: var(--app-avatar-bg-color);
  font-size: 3rem;
}
.order-creation-date {
  text-align: left;
  font-size: 0.9rem;
}
.order-price {
  text-align: left;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 2rem;
}
.list-item-buyer {
  text-align: left;
  color: #646464;
  font-size: 0.9rem;
  margin-bottom: 0.75rem;
}
/* 600px */
@media only screen and (max-width: 37.5rem) {
  .list-item-title {
    padding-top: 0.125rem;
    font-size: 0.938rem;
    line-height: 1.125rem;
  }
}
</style>
